/* eslint-disable react/prop-types */
// @ts-check

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FaCircleCheck } from 'react-icons/fa6';
import { ButtonPillOutline } from '../../Button';
import { useUserSettings } from '../../UserSettings/Context';
import { AnalyticsAndAdvertisingMenuItems } from '../../UserSettings/AnalyticsAndAdvertising/Content';
import { AdvertisingsModalTab } from '../../UserSettings/AnalyticsAndAdvertising/Advertising/Content';

/**
 * @typedef {{
 * 	closeToast: () => void;
 * }} NotificationToastSponsorAdProps
 * */

const NotificationToastSponsorAd = (
	/** @type {NotificationToastSponsorAdProps} */
	{ closeToast },
) => {
	const { t } = useTranslation();

	const { openAnalyticsAndAdModal } = useUserSettings();

	const handleOpenRequest = useCallback(() => {
		openAnalyticsAndAdModal(
			AnalyticsAndAdvertisingMenuItems.ADVERTISING,
			AdvertisingsModalTab.SPONSOR_ADS,
		);
		closeToast();
	}, [openAnalyticsAndAdModal, closeToast]);

	return (
		<>
			<div className="d-flex align-items-center">
				<FaCircleCheck className="text-primary font-size-xl mr-3" />
				<div>
					<h6 className="mb-0 font-weight-bold">{t('Notification.Toast.sponsorAdRequest')}</h6>
					<p className="mb-0 text-secondary small">{t('Notification.Toast.sponsorOpportunity')}</p>
				</div>
			</div>
			<ButtonPillOutline
				className="btn-primary btn-sm mt-2 w-100"
				onClick={handleOpenRequest}
			>
				{t('Notification.Toast.openRequest')}
			</ButtonPillOutline>
		</>
	);
};

export default NotificationToastSponsorAd;
