import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useAsync } from 'react-async-hook';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';

import { fetchBeeyouAd, fetchPersonalizedAds } from '../../../api/ads/ads';
import { fetchAds } from '../../../api/public/ads';
import { useAsyncErrorLog } from '../../../lib/hooks';
import { useAuthentication } from '../../Authentication/Authentication';
import { ButtonPill } from '../../Button';
import { AdVideoPlayer } from './Player';
import { getLanguageKey } from '../../../i18n';
import { useAd } from '../Context';
import { DEFAULT_AD_DURATION } from '../Provider';
import { useStudio } from '../../Studio/Context';

const SKIP_AD_TIMEOUT = 5000;

export const AdVideo = ({
	beeyouAd,
	canSkip,
	countInIncome, // TODO: consider countInIncome param when calculating ad revenue (like hosts don't count in billing)
	duration,
	onEnded,
	onSkip,
	transparentOnLoading,
	studioId,
	...props
}) => {
	const { t, i18n: { language } } = useTranslation();
	const { isLoggedIn } = useAuthentication();
	const { isAdPlaying, setIsAdPlaying, handleAdEnded } = useAd();
	const { studio } = useStudio();

	const [ads, setAds] = useState();
	const [skipAdTimeoutExpired, setSkipAdTimeoutExpired] = useState(false);
	const [skipAdRemaining, setSkipAdRemaining] = useState(5);
	const [isRequestingPlay, setIsRequestingPlay] = useState(false);
	const [isAdLoaded, setIsAdLoaded] = useState(false);

	const { error } = useAsync(async () => {
		// eslint-disable-next-line no-nested-ternary
		const { data } = await (beeyouAd
			? fetchBeeyouAd()
			: (isLoggedIn
				? fetchPersonalizedAds(duration, studioId)
				: fetchAds(duration, getLanguageKey(language))
			));

		if (!data || !data.ads.length) {
			onEnded();
			return;
		}

		setAds(data.ads);
	}, []);

	useAsyncErrorLog({ error });

	// On unmount, we reset the ad state
	useEffect(() => () => handleAdEnded(), [handleAdEnded]);

	useEffect(() => {
		if (isAdPlaying) {
			const time = Date.now() + SKIP_AD_TIMEOUT;
			const interval = setInterval(() => {
				const remainingSeconds = Math.round((time - Date.now()) / 1000);
				setSkipAdRemaining(remainingSeconds);
			}, 1000);

			return () => { clearInterval(interval); };
		}
		return undefined;
	}, [isAdPlaying]);

	useEffect(() => {
		if (isAdPlaying) {
			const timeout = setTimeout(() => {
				setSkipAdTimeoutExpired(true);
			}, SKIP_AD_TIMEOUT);

			return () => { clearTimeout(timeout); };
		}
		return undefined;
	}, [isAdPlaying]);

	const handleClickSkipAd = useCallback(() => {
		ReactGA.event({ category: 'studio', action: 'skip_ad', label: 'User clicked on skip ad button' });
		onSkip();
	}, [onSkip]);

	const handleWatchAd = useCallback(() => setIsRequestingPlay(true), []);
	const handleAdPlaying = useCallback(() => setIsAdPlaying(true), [setIsAdPlaying]);

	const showAd = !transparentOnLoading || isAdLoaded;

	useEffect(() => {
		if (ads && !ads.length && onSkip) {
			handleClickSkipAd();
		}
	}, [ads, handleClickSkipAd, onSkip]);

	const controls = !studio;

	return (
		<div className={`d-${showAd ? 'flex' : 'none'} h-100 w-100 overflow-hidden align-items-center`}>
			{ads?.length && (
				<AdVideoPlayer
					controls={controls}
					handleAdPlaying={handleAdPlaying}
					hideTooltip={beeyouAd}
					isRequestingPlay={isRequestingPlay}
					isSkippable={!!onSkip}
					onAdLoaded={() => setIsAdLoaded(true)}
					onEnded={onEnded}
					videos={ads}
					{...props}
				/>
			)}
			{!isAdPlaying && (
				<ButtonPill
					className="position-absolute zIndex-2"
					onClick={handleWatchAd}
					style={{
						bottom: `calc(4%${controls ? ' + 46px' : ''})`,
						right: '2%',
					}}
				>
					{t('Video.watch')}
				</ButtonPill>
			)}
			{isAdPlaying && canSkip && (
				<ButtonPill
					className="position-absolute zIndex-2"
					disabled={!skipAdTimeoutExpired}
					onClick={handleClickSkipAd}
					style={{
						bottom: `calc(4%${controls ? ' + 46px' : ''})`,
						right: '2%',
					}}
				>
					{skipAdTimeoutExpired ? t('Video.skip') : t('Video.skipRemaining', { skipAdRemaining })}
				</ButtonPill>
			)}
		</div>
	);
};

AdVideo.propTypes = {
	beeyouAd: PropTypes.bool,
	canSkip: PropTypes.bool,
	countInIncome: PropTypes.bool,
	duration: PropTypes.number,
	onEnded: PropTypes.func,
	onSkip: PropTypes.func,
	transparentOnLoading: PropTypes.bool,
};

AdVideo.defaultProps = {
	beeyouAd: false,
	canSkip: false,
	countInIncome: true,
	duration: DEFAULT_AD_DURATION,
	onEnded: undefined,
	onSkip: undefined,
	transparentOnLoading: false,
};
