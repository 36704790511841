// @ts-check
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { useTranslation } from 'react-i18next';

import { ResourceAccessRole } from '../../../lib/ResourceAccessRole';
import { getShareLink, ShareTabType } from '../Share.helper';
import { ShareRoleInput } from '../RoleInput';

import beeyouLogo from '../../../../public/beeyou.png';

/**
 * @typedef {{
* 	channel?: { _id: string };
* 	studio?: { subject: string, code: string; password: string; watchLinkId: string };
* 	video?: { watchLinkId: string };
*   roles?: [ResourceAccessRole];
*   selectedRole?: ResourceAccessRole;
*   setSelectedRole?: (role: ResourceAccessRole) => void;
*   post?: { slug: string };
* }} ShareTabQRCodeProps
*/

export const ShareTabQRCode = ({
	/** @type {ShareTabQRCodeProps} */
	channel,
	post,
	roles,
	selectedRole,
	setSelectedRole,
	studio,
	video,
}) => {
	const [downloadUrl, setDownloadUrl] = useState();
	const { t } = useTranslation();
	const link = getShareLink({ channel, studio, video, role: selectedRole, post });

	useEffect(() => {
		const canvas = (document.getElementById('qrcodecanvas'));
		if (canvas) {
			setDownloadUrl(
				canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream'),
			);
		}
	}, [selectedRole]);

	return (
		<div>
			<p>{t('Profile.Qrcode.share')}</p>
			<div className="d-flex flex-column flex-sm-row justify-content-start justify-content-sm-between align-items-start align-items-sm-center pl-1">
				<ShareRoleInput
					value={selectedRole}
					onChange={(role) => setSelectedRole(role)}
					roles={roles}
					shareTabType={ShareTabType.LINK}
				/>
			</div>
			<div className="d-flex justify-content-center">
				<div className="p-3 bg-white rounded">
					<QRCodeCanvas
						id="qrcodecanvas"
						className="mr-auto ml-auto"
						value={link}
						size={256}
						level="H"
						imageSettings={{
							src: beeyouLogo,
							height: 60,
							width: 50,
							excavate: true,
						}}
					/>
				</div>
			</div>
			<div className="mt-2 d-flex">
				<a
					className="mr-auto ml-auto"
					href={downloadUrl}
					download={`qrcode_${studio?.subject || 'studio'}.png`}
					target="_blank"
					rel="noopener noreferrer"
					title={t('Profile.Qrcode.download')}
				>
					{t('Profile.Qrcode.download')}
				</a>
			</div>
		</div>
	);
};

ShareTabQRCode.propTypes = {
	channel: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}),
	roles: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ResourceAccessRole))),
	studio: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		subject: PropTypes.string,
	}),
	video: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}),
	post: PropTypes.shape({}),
	selectedRole: PropTypes.oneOf(Object.keys(ResourceAccessRole)).isRequired,
	setSelectedRole: PropTypes.func.isRequired,
};

ShareTabQRCode.defaultProps = {
	channel: undefined,
	roles: [],
	studio: undefined,
	video: undefined,
	post: undefined,
};
