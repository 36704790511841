// @ts-check

import {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
	useRef,
	useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useAsyncCallback } from 'react-async-hook';
import { useRouteMatch } from 'react-router-dom';

import * as voteApi from '../../api/channel/vote';
import * as publicVoteApi from '../../api/public/vote';
import { ListSortingType } from '../ListSorting/Provider';
import { useStudio } from '../Studio/Context';
import { Path, getLink } from '../../RoutePath';

export const VoteAudienceType = {
	PARTICIPANT: 'PARTICIPANT',
	VIEWER: 'VIEWER',
};

export const VIEWER_DELAY = 30; // In seconds

const VoteContext = createContext({});

export const useVote = () => useContext(VoteContext);

export const VoteProvider = ({ children }) => {
	const { activeContent, setActiveContent, studio } = useStudio();

	const [activeVote, setActiveVote] = useState(null);
	const [previewableVote, setPreviewableVote] = useState(null);
	const [votes, setVotes] = useState(null);
	const [report, setReport] = useState(null);
	const [voteTemplates, setVoteTemplates] = useState(null);
	const [viewerDelay, setViewerDelay] = useState(0); // In milliseconds

	const nextVoteTimerRef = useRef();
	const previewableVoteTimerRef = useRef();
	const activeVotesRef = useRef();

	const isOperatorRoute = useRouteMatch({ path: getLink(
		Path.STUDIO_OPERATOR,
		{ hashtag: studio?.owner?.hashtag, code: studio?.code },
	) });
	const isHostRoute = useRouteMatch({ path: getLink(
		Path.STUDIO_HOST,
		{ hashtag: studio?.owner?.hashtag, code: studio?.code },
	) });
	const isOperator = isOperatorRoute?.isExact || isHostRoute?.isExact;

	const fetchVotes = useCallback(async (studioId) => {
		const { data } = await voteApi.fetchChannelVotesByStudioId(studioId);

		if (data) {
			setVotes(data);
		}
	}, [setVotes]);

	const fetchReport = useCallback(async (studioId) => {
		if (!report) {
			const { data } = await voteApi.fetchReport(studioId);

			if (data) {
				const filteredVotesReport = {
					[VoteAudienceType.VIEWER]: data
						.filter((vote) => vote.audienceType === VoteAudienceType.VIEWER),
					[VoteAudienceType.PARTICIPANT]: data
						.filter((vote) => vote.audienceType === VoteAudienceType.PARTICIPANT),
				};
				setReport(filteredVotesReport);
			}
		}
	}, [report]);

	const fetchReportCSV = useCallback(async (studioId, audienceType) => {
		const { data } = await voteApi.fetchReportCSV(studioId, audienceType);

		return data;
	}, []);

	const createVotes = useCallback(async (studioId, votesData, optionalParameters) => {
		await voteApi.createVotes(studioId, votesData, optionalParameters);
	}, []);

	const endVote = useCallback(async (voteId) => {
		await voteApi.endVote(voteId);
	}, []);

	const voteOnVote = useCallback(
		/**
		 * @param {string} voteId
		 * @param {string} answerId
		 * @param {boolean} isGuest
		 */
		async (voteId, answerId, isGuest = false) => {
			if (isGuest) {
				await publicVoteApi.publicVoteOnVote(voteId, answerId);
			} else {
				await voteApi.voteOnVote(voteId, answerId);
			}
		}, [],
	);

	const publishVote = useCallback(async (voteId) => {
		await voteApi.publishVote(voteId);
	}, []);

	const endVotePublish = useCallback(async (voteId) => {
		await voteApi.endPublishVote(voteId);
	}, []);

	const fetchVoteTemplates = useCallback(async (
		isArchived,
		itemsPerPage = -1,
		currentPage = -1,
		currentSort = ListSortingType.OLDEST_TO_NEWEST,
	) => {
		const sortValue = currentSort === ListSortingType.OLDEST_TO_NEWEST ? 1 : -1;
		const { data } = await voteApi.fetchChannelVoteTemplates(
			isArchived,
			itemsPerPage,
			currentPage,
			sortValue,
		);
		if (data) {
			setVoteTemplates(data);
		}
	}, [setVoteTemplates]);

	const createVoteTemplate = useCallback(async (voteTemplate) => {
		await voteApi.createVoteTemplate(voteTemplate);
	}, []);

	const deleteVoteTemplate = useCallback(async (voteTemplateId) => {
		await voteApi.deleteVoteTemplate(voteTemplateId);

		setVoteTemplates((templates) => ({
			totalItemsCount: templates.totalItemsCount - 1,
			data: templates.data.filter((template) => template._id !== voteTemplateId),
		}));
	}, []);

	const restoreVoteTemplate = useCallback(async (voteTemplateId) => {
		const { data } = await voteApi.restoreVoteTemplate(voteTemplateId);
		return data;
	}, []);

	const sendReportThroughMail = useCallback(async (studioId, content) => {
		await voteApi.sendReportThroughMail(studioId, content);
	}, []);

	const setPreviewableVoteWithTimeout = useCallback((vote) => {
		if (previewableVoteTimerRef.current) clearTimeout(previewableVoteTimerRef.current);

		setPreviewableVote(vote);

		const removePreviewableVoteTime = (5 + (vote.audienceType === VoteAudienceType.VIEWER
			? VIEWER_DELAY : 0)) * 1000 + new Date(vote.endedAt).getTime() - Date.now();

		previewableVoteTimerRef.current = setTimeout(() => {
			setPreviewableVote(null);
			setReport(null);
		}, removePreviewableVoteTime);
	}, []);

	const createActiveVote = useCallback(({ activeVotes = [], realViewerDelay = 0 }) => {
		if (nextVoteTimerRef.current) clearTimeout(nextVoteTimerRef.current);

		const activeVoteIndex = activeVotes
			.findIndex((vote) => (new Date(vote.endedAt).getTime() + realViewerDelay) > Date.now());
		const previewableVoteIndex = activeVoteIndex === -1 ? activeVotes.length - 1 : activeVoteIndex;

		const vote = activeVotes.length > 1 ? {
			...activeVotes[previewableVoteIndex],
			playlistInfo: {
				position: previewableVoteIndex + 1,
				total: activeVotes.length,
			},
		} : activeVotes[previewableVoteIndex];

		if (activeVoteIndex !== -1) {
			setActiveVote(vote);

			nextVoteTimerRef.current = setTimeout(() => {
				createActiveVote({ activeVotes, realViewerDelay });
			}, new Date(activeVotes[activeVoteIndex].endedAt).getTime() - Date.now() + realViewerDelay);
		} else {
			activeVotesRef.current = null;
			setActiveVote(null);
			setViewerDelay(0);
		}

		if (previewableVoteIndex !== -1) setPreviewableVoteWithTimeout(vote);
	}, [setPreviewableVoteWithTimeout]);

	// Update active vote timings when viewer delay changes
	useEffect(() => {
		if (viewerDelay) {
			createActiveVote({ activeVotes: activeVotesRef.current, realViewerDelay: viewerDelay });
		}
	}, [createActiveVote, viewerDelay]);

	const handleEventVotesActiveNew = useCallback((newActiveVotes) => {
		activeVotesRef.current = newActiveVotes;

		// Set viewer delay to default if the vote is for viewers and there is no active vote
		const realViewerDelay = newActiveVotes.length > 0
			&& newActiveVotes[0].audienceType === VoteAudienceType.VIEWER && !viewerDelay
			? VIEWER_DELAY * 1000 : viewerDelay;

		createActiveVote({
			activeVotes: newActiveVotes,
			realViewerDelay: isOperator ? 0 : realViewerDelay,
		});
	}, [createActiveVote, isOperator, viewerDelay]);

	// Set active vote when joining studio
	useEffect(() => {
		if (activeContent?.activeVotes && activeContent.activeVotes.length > 0) {
			handleEventVotesActiveNew(activeContent.activeVotes);
			setActiveContent((state) => ({
				...state,
				activeVotes: [],
			}));
		}
	}, [activeContent?.activeVotes, handleEventVotesActiveNew, setActiveContent]);

	const handleEventVoteActiveUpdate = useCallback((updatedVote) => {
		// Update current votes
		activeVotesRef.current = activeVotesRef.current
			?.map((vote) => (vote._id === updatedVote._id ? updatedVote : vote));

		// Update active vote
		createActiveVote({ activeVotes: activeVotesRef.current || [updatedVote] });
	}, [createActiveVote]);

	const sendReportThroughMailAsync = useAsyncCallback(sendReportThroughMail);

	useEffect(() => {
		if (sendReportThroughMailAsync.status === 'success' || sendReportThroughMailAsync.error) {
			const id = setTimeout(() => sendReportThroughMailAsync.reset(), 3000);
			return () => clearTimeout(id);
		}
		return undefined;
	}, [sendReportThroughMailAsync]);

	const fetchReportCSVAsync = useAsyncCallback(fetchReportCSV);

	const value = useMemo(() => ({
		activeVote,
		createVotes,
		createVoteTemplate,
		deleteVoteTemplate,
		endVote,
		endVotePublish,
		fetchReport,
		fetchReportCSV,
		fetchReportCSVAsync,
		fetchVotes,
		fetchVoteTemplates,
		handleEventVoteActiveUpdate,
		handleEventVotesActiveNew,
		previewableVote,
		publishVote,
		report,
		restoreVoteTemplate,
		sendReportThroughMail,
		sendReportThroughMailAsync,
		setViewerDelay,
		viewerDelay,
		voteOnVote,
		votes,
		voteTemplates,
	}), [
		activeVote,
		createVotes,
		createVoteTemplate,
		deleteVoteTemplate,
		endVote,
		endVotePublish,
		fetchReport,
		fetchReportCSV,
		fetchReportCSVAsync,
		fetchVotes,
		fetchVoteTemplates,
		handleEventVoteActiveUpdate,
		handleEventVotesActiveNew,
		previewableVote,
		publishVote,
		report,
		restoreVoteTemplate,
		sendReportThroughMail,
		sendReportThroughMailAsync,
		setViewerDelay,
		viewerDelay,
		voteOnVote,
		votes,
		voteTemplates,
	]);

	return (
		<VoteContext.Provider value={value}>
			{children}
		</VoteContext.Provider>
	);
};

VoteProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
