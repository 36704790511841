import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { ReportType } from './Context';

export const ReportDetailsOverview = ({ report, type }) => {
	const { t } = useTranslation();

	return (
		<div className="p-3">
			<div>
				<Row className="mb-2">
					<Col xs="2" className="font-weight-bold content-dark">{t('ReportDetailsOverview.reporter')}</Col>
					<Col xs="10" className="text-secondary">{report.reporterHashtag}</Col>
				</Row>
				{type === ReportType.CHANNEL && (
					<Row className="mb-2">
						<Col xs="2" className="font-weight-bold content-dark">{t('ReportDetailsOverview.user')}</Col>
						<Col xs="10" className="text-secondary">{report.userHashtag || report?.guestNickname}</Col>
					</Row>
				)}
				{type === ReportType.VIDEO && (
					<Row className="mb-2">
						<Col xs="2" className="font-weight-bold content-dark">{t('ReportDetailsOverview.video')}</Col>
						<Col xs="10" className="text-secondary">{report.label}</Col>
					</Row>
				)}
				<Row className="mb-2">
					<Col xs="2" className="font-weight-bold content-dark">{t('ReportDetailsOverview.category')}</Col>
					<Col xs="10" className="text-secondary">{report.categoryName}</Col>
				</Row>
				<p className="mt-4 content-dark">{report.reason}</p>
			</div>
		</div>
	);
};

ReportDetailsOverview.propTypes = {
	report: PropTypes.shape({
		_id: PropTypes.string,
		reporterHashtag: PropTypes.string,
		userHashtag: PropTypes.string,
		categoryName: PropTypes.string,
		reason: PropTypes.string,
		label: PropTypes.string,
	}).isRequired,
	type: PropTypes.string.isRequired,
};
